import Image from "next/image";

import { cn } from "@gility/lib";

export type ProductCoverProps = {
  className?: string;
  width?: number | `${number}`;
  height?: number | `${number}`;
  live?: boolean;
  onSite?: boolean;
  onDemand?: boolean;
  alt: string;
  src?: string | null;
};

const ProductCover = ({
  className,
  src,
  alt,
  width,
  height,
  live = false,
  onSite = false,
  onDemand = false,
}: ProductCoverProps) =>
  src ? (
    <div className={cn("relative aspect-gility overflow-hidden rounded-t-xl", className)}>
      <Image
        className="aspect-gility h-full w-full object-cover object-center"
        src={src}
        alt={alt}
        width={width}
        height={height}
        priority
      />
      {live && (
        <span className="absolute right-1 top-1 rounded-md bg-pink px-2 py-1 text-xs font-medium text-white">
          {onSite ? "In presenza" : "Live"}
        </span>
      )}
      {/* 
      TODO: refactor all usages of ProductCover to specify wether we wanna show the badge instead of using live/onSite
      
      we do the thing below instead of checking !live because in most usages of ProductCover, live/onSite 
      is not passed intentionally to not show the badge and if we did !live, we would show the OnDemand
      badge even if no badge was not passed */}
      {onDemand && (
        <span className="absolute right-1 top-1 rounded-md bg-pink px-2 py-1 text-xs font-medium text-white">
          On demand
        </span>
      )}
    </div>
  ) : null;

export default ProductCover;
