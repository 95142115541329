export const parseImageSrc = (image?: string | null) => {
  if (!image) return null;
  try {
    return new URL(image).href;
  } catch (_) {
    return `https:${image}`;
  }
};

export const toTime = (str?: string | number | null): number => {
  return (
    str
      ?.toString()
      .trim()
      .split(" ")
      .map((pc) => convertTimeSegment(pc))
      .reduce((acc, val) => acc + val, 0) || 0
  );
};

export const toPercentage = (str?: string | number | null): number => {
  return parseInt((str?.toString().trim() || "0%").split("%")[0] || "0");
};

const convertTimeSegment = (piece?: string | number | null): number => {
  if (!piece) return 0;
  if (typeof piece === "number") return piece;

  if (piece.endsWith("sec")) {
    return parseInt((piece.split("sec")[0] || "0").trim());
  } else if (piece.endsWith("min")) {
    return parseInt((piece.split("min")[0] || "0").trim()) * 60;
  } else if (piece.endsWith("ore")) {
    return parseInt((piece.split("ore")[0] || "0").trim()) * 60 * 60;
  } else {
    throw new Error(`Cannot convert time string '${piece}'`);
  }
};
